import * as Yup from "yup";

const InfoPostFormSchema = Yup.object().shape({
  heading: Yup.string().required(),
  activate_on: Yup.date()
    .nullable()
    .when("deactivate_on", {
      is: (value) => !!value,
      then: Yup.date().nullable().required("Start of live period is required."),
    }),
  deactivate_on: Yup.date()
    .nullable()
    .min(
      Yup.ref("activate_on"),
      "End of live period cannot be earlier or equal to when it begins."
    ),
  ui_action_attributes: Yup.object().shape({
    action_type: Yup.string().nullable(),
    payload: Yup.object()
      .shape({})
      .when("action_type", {
        is: (value) => value === "call",
        then: Yup.object().shape({
          phone_no: Yup.string().required("Phone number is required"),
        }),
      })
      .when("action_type", {
        is: (value) => value === "browser",
        then: Yup.object().shape({
          url: Yup.string().required("URL is required"),
        }),
      })
      .when("action_type", {
        is: (value) => value === "email",
        then: Yup.object().shape({
          email_address: Yup.string().required("Email address is required"),
        }),
      }),
  }),
});

export default InfoPostFormSchema;
