import Trix from "trix";
import { debounce } from "lodash";

class TextColorTrixAttribute {
  addTrixAttributes = () => {
    Trix.config.textAttributes.foregroundColor = {
      styleProperty: "color",
      inheritable: true,
      parser: this.createColorParser("color"),
    };
  };

  removeTrixAttributes = () => {
    Trix.config.textAttributes.foregroundColor = undefined;
  };

  getHexColor = (color) => {
    if (color) {
      if (/^#/.test(color)) {
        return color;
      }
      const rgbValues = this.getRGBValues(color);
      const hexValues = rgbValues.map(this.numberToHex);
      return "#" + hexValues.join("");
    }
    return "";
  };

  numberToHex = (number) => {
    return `0${number.toString(16)}`.slice(-2).toUpperCase();
  };

  getRGBValues = (color) => {
    const match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/i);
    let r, g, b;
    if (match) {
      [, r, g, b] = match;
    } else {
      canvas = document.createElement("canvas");
      canvas.height = canvas.width = 1;
      context = canvas.getContext("2d");
      context.fillStyle = color;
      context.fillRect(0, 0, 1, 1);
      const { data } = context.getImageData(0, 0, 1, 1);
      [r, g, b] = data;
    }

    return [r, g, b].map(Number);
  };

  createColorParser = (style) => {
    return (element) => {
      while (element && element.tagName !== "TRIX-EDITOR") {
        const styleColor = element.style[style];
        if (styleColor) {
          const color = this.getHexColor(styleColor);
          if (color) {
            return color;
          }
        }
        element = element.parentElement;
      }
    };
  };

  attach = () => {
    this.addTrixAttributes();
  };

  detach = () => {
    this.removeTrixAttributes();
  };
}

class AddTextColorButton {
  getEditor = () => {
    return document.querySelector("trix-editor").editor;
  };

  getButtonGroup = (toolBarElement) => {
    return toolBarElement.querySelector(
      ".trix-button-group.trix-button-group--text-tools"
    );
  };

  createColorInput = () => {
    if (!this.colorInput) {
      this.colorInput = $(
        $("<input></input>").attr({
          type: "color",
        })
      );
    }
    return this.colorInput;
  };

  createColorButton = () => {
    if (!this.colorButton) {
      this.colorButton = $("<button></button>")
        .attr({
          type: "button",
          class: "trix-button",
        })
        .append(
          $("<p>A</p>").css({
            marginBottom: 0,
            marginRight: "12px",
            display: "inline-block",
          })
        );
    }
    return this.colorButton;
  };

  onColorInputInputs = (colorInput) => {
    const editor = this.getEditor();
    colorInput.on(
      "input",
      debounce((event) => {
        const color = $(event.target).val();
        const selection = editor.getSelectedRange();
        if (selection) {
          editor.activateAttribute("foregroundColor", color);
        }
      })
    );
  };

  onColorButtonPress = (colorButton, colorInput) => {
    colorButton[0].addEventListener("click", () => {
      colorInput.focus();
      colorInput.click();
    });
  };

  updateColorInputColor = (color) => {
    const colorInput = this.createColorInput();
    if (color) {
      colorInput.val(color);
    } else {
      colorInput.val(null);
    }
  };

  onTrixInitialize = (event) => {
    const buttonGroup = this.getButtonGroup(event.target.toolbarElement);
    const colorInput = this.createColorInput();
    const colorButton = this.createColorButton().append(colorInput);
    this.onColorInputInputs(colorInput);
    this.onColorButtonPress(colorButton, colorInput);
    buttonGroup.appendChild(colorButton[0]);
  };

  onTrixSelectionChange = () => {
    const editor = this.getEditor();
    const position = editor.getPosition();
    const range = editor.getSelectedRange();
    let foregroundColor = null;

    if (range && range[0] === position && range[1] === position) {
      ({ foregroundColor } = editor
        .getDocument()
        .getCommonAttributesAtPosition(position));
    } else {
      const colors = [];
      editor
        .getSelectedDocument()
        .getObjects()
        .forEach((obj) => {
          if (obj.attributes && obj.attributes.values) {
            Object.entries(obj.attributes.values).forEach(([key, value]) => {
              if (key === "foregroundColor") {
                colors.push(value);
              }
            });
          }
        });

      if (colors.length === 1) {
        foregroundColor = colors[0];
      }
    }

    this.updateColorInputColor(foregroundColor);
  };

  attach = () => {
    document.addEventListener("trix-initialize", this.onTrixInitialize);
    document.addEventListener(
      "trix-selection-change",
      this.onTrixSelectionChange
    );
  };

  detach = () => {
    document.removeEventListener("trix-initialize", this.onTrixInitialize);
    document.removeEventListener(
      "trix-selection-change",
      this.onTrixSelectionChange
    );
  };
}

export { TextColorTrixAttribute };
export default AddTextColorButton;
