import Trix from "trix";

class HeadingsTrixAttributes {
  addTrixAttributes = () => {
    Trix.config.blockAttributes.head1 = {
      tagName: "h1",
      terminal: true,
      breakOnReturn: true,
      group: false,
    };

    Trix.config.blockAttributes.head2 = {
      tagName: "h2",
      terminal: true,
      breakOnReturn: true,
      group: false,
    };

    Trix.config.blockAttributes.head3 = {
      tagName: "h3",
      terminal: true,
      breakOnReturn: true,
      group: false,
    };

    Trix.config.blockAttributes.head4 = {
      tagName: "h4",
      terminal: true,
      breakOnReturn: true,
      group: false,
    };
  };

  removeTrixAttributes = () => {
    Trix.config.blockAttributes.head1 = undefined;
    Trix.config.blockAttributes.head2 = undefined;
    Trix.config.blockAttributes.head3 = undefined;
    Trix.config.blockAttributes.head4 = undefined;
  };

  attach() {
    this.addTrixAttributes();
  }

  detach() {
    this.removeTrixAttributes();
  }
}

class AddHeadingButtons {
  textButtonsToAdd = [
    // ["H1", "head1"],
    ["H2", "head2"],
    ["H3", "head3"],
    ["H4", "head4"],
  ];

  getEditor = () => {
    return document.querySelector("trix-editor").editor;
  };

  getButtonGroup = (toolBarElement) => {
    return toolBarElement.querySelector(
      ".trix-button-group.trix-button-group--text-tools"
    );
  };

  addButtons = (buttonGroup) => {
    if (buttonGroup) {
      this.textButtonsToAdd.forEach(([name, action]) => {
        const button = $("<button></button>")
          .attr({
            type: "button",
            class: "trix-button",
            "data-trix-attribute": action,
          })
          .html(name)[0];

        buttonGroup.appendChild(button);
      });
    }
  };

  removeOgH1Button = (toolBarElement) => {
    const ogH1Toggle = toolBarElement.querySelector(
      '.trix-button-group.trix-button-group--block-tools button[data-trix-attribute="heading1"]'
    );
    if (ogH1Toggle) {
      ogH1Toggle.remove();
    }
  };

  onTrixInitialize = (event) => {
    const buttonGroup = this.getButtonGroup(event.target.toolbarElement);
    this.addButtons(buttonGroup);
    this.removeOgH1Button(event.target.toolbarElement);
  };

  attach() {
    document.addEventListener("trix-initialize", this.onTrixInitialize);
  }

  detach() {
    document.removeEventListener("trix-initialize", this.onTrixInitialize);
  }
}

export { HeadingsTrixAttributes };
export default AddHeadingButtons;
