import React from "react";
import DatePicker from "react-datepicker";
import { Row, Col, Label, FormText } from "reactstrap";

const DateTimeRange = (props) => {
  const {
    inputTitle,
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    startDateTextLabel,
    endDateTextLabel,
    startDateFor,
    endDateFor,
    startDateError,
    endDateError,
  } = props;

  return (
    <div className="date-time-range">
      <Row className="left-padding">
        <Col>
          <h4>{inputTitle}</h4>
        </Col>
      </Row>
      <div style={{ height: 16 }} />
      <Row className="left-padding">
        <Col sm={2}>
          <Label for={startDateFor}>{startDateTextLabel}</Label>
        </Col>
        <Col sm={4}>
          <DatePicker
            id={startDateFor}
            name={startDateFor}
            startDate={startDate}
            endDate={endDate}
            selected={startDate}
            onChange={onStartDateChange}
            showTimeSelect={true}
            showMonthDropdown={true}
            showYearDropdown={true}
            selectsStart={true}
            todayButton="Today"
            className={`borderless margin-right ${
              startDateError ? "is-invalid" : ""
            }`}
            placeholderText="Select Date & Time"
            dateFormat="dd/MM/yyyy h:mm aa"
            isClearable={true}
            closeOnScroll={true}
          />
          {startDateError ? (
            <FormText color="danger">{startDateError}</FormText>
          ) : null}
        </Col>
        <Col sm={2}>
          <Label for={endDateFor}>{endDateTextLabel}</Label>
        </Col>
        <Col sm={4}>
          <DatePicker
            id={endDateFor}
            name={endDateFor}
            startDate={startDate}
            endDate={endDate}
            selected={endDate}
            onChange={onEndDateChange}
            showTimeSelect={true}
            showMonthDropdown={true}
            showYearDropdown={true}
            selectsEnd={true}
            todayButton="Today"
            className={`borderless margin-right ${
              endDateError ? "is-invalid" : ""
            }`}
            placeholderText="Select Date & Time"
            dateFormat="dd/MM/yyyy h:mm aa"
            isClearable={true}
            closeOnScroll={true}
          />
          {endDateError ? (
            <FormText color="danger">{endDateError}</FormText>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default DateTimeRange;
