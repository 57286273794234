import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  Button,
  FormFeedback,
} from "reactstrap";
import { useFormik } from "formik";
import { serialize } from "object-to-formdata";
import Turbolinks from "turbolinks";
import LabelRow from "../layouts/LabelRow";
import Input from "../inputs/Input";
import ImageSelection from "../inputs/ImageSelection";
import CantikCheckboxGroup from "../inputs/CantikCheckboxGroup";
import DateTimeRange from "../inputs/DateTimeRange";
import UiAction from "../inputs/UiAction";
import TrixEditor from "../inputs/TrixEditor";
import InfoPostFormSchema from "../schemas/InfoPostFormSchema";

const shouldSend = process.env.NODE_ENV === "production" || true;

const organiseUiActionAttributes = (uiActionAttributes) => {
  if (uiActionAttributes.action_type === "none") {
    if (uiActionAttributes.id) {
      return {
        id: uiActionAttributes.id,
        _destroy: true,
      };
    } else {
      return null;
    }
  }
  return uiActionAttributes;
};

const produceUiActionAttribute = () => {
  return {
    action_type: "none",
    call_to_action_text: "",
    payload: {
      phone_no: "",
      url: "",
      email_address: "",
    },
  };
};

const generateInitialValues = (currentValues) => {
  if (currentValues) {
    if (currentValues.activate_on) {
      currentValues.activate_on = new Date(currentValues.activate_on);
    }
    if (currentValues.deactivate_on) {
      currentValues.deactivate_on = new Date(currentValues.deactivate_on);
    }
    if (!currentValues.ui_action_attributes) {
      currentValues.ui_action_attributes = produceUiActionAttribute();
    }
    return currentValues;
  } else {
    return {
      heading: "",
      info: null,
      remark: "",
      activate_on: null,
      deactivate_on: null,
      image: null,
      patron_category_ids: ["all"],
      ui_action_attributes: produceUiActionAttribute(),
    };
  }
};

const InfoPostForm = (props) => {
  const { infoPost, patronCategories, url, method } = props;
  const [submitting, setSubmitting] = useState(false);
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
  } = useFormik({
    initialValues: generateInitialValues(infoPost),
    validationSchema: InfoPostFormSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values, { setErrors }) => {
      const toSubmit = { ...values };
      if (toSubmit.patron_category_ids.includes("all")) {
        toSubmit.patron_category_ids = patronCategories.map((pc) => pc.value);
      }
      if (
        !toSubmit.image ||
        (typeof toSubmit.image === "string" &&
          toSubmit.image.includes("active_storage/blobs"))
      ) {
        delete toSubmit.image;
      }
      toSubmit.ui_action_attributes = organiseUiActionAttributes(
        toSubmit.ui_action_attributes
      );
      // console.log(toSubmit);
      const formData = serialize(toSubmit, {}, null, "info_post");
      //   for(var pair of formData.entries()) {
      //     console.log(pair[0]+ ', '+ pair[1]);
      //  }

      const token = document.querySelector("[name=csrf-token]").content;

      if (shouldSend) {
        setSubmitting(true);
        fetch(url, {
          method,
          body: formData,
          credentials: "same-origin",
          headers: {
            "X-CSRF-TOKEN": token,
          },
        })
          .then((res) => {
            setSubmitting(false);
            return res.json();
          })
          .then((res) => {
            if (res.redirect_url) {
              Turbolinks.visit(res.redirect_url, { action: "replace" });
            } else {
              setErrors(res);
            }
          })
          .catch((err) => {
            // TODO
          });
      }
    },
  });

  // console.log(errors)

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <LabelRow
          labelText="To"
          labelFor="patron_category_ids"
          labelColClassNames="d-flex align-items-center"
          labelStyle={{ margin: 0 }}
        >
          <CantikCheckboxGroup
            defaultCheckbox={{ name: "All", value: "all" }}
            checkboxes={patronCategories}
            initialSelected={values.patron_category_ids}
            onChange={(values) => setFieldValue("patron_category_ids", values)}
            error={errors.patron_category_ids}
          />
        </LabelRow>
      </FormGroup>
      <hr />
      <br />
      <FormGroup>
        <InputGroup size="lg">
          <Input
            className="borderless h1-input"
            type="text"
            name="heading"
            id="heading"
            placeholder="Heading"
            onChange={handleChange("heading")}
            value={values.heading}
            required={true}
            error={errors.heading}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup className="loyalty-trix">
        <TrixEditor
          initialValue={values.info}
          onChange={handleChange("info")}
          name="info"
          removeLinking={true}
          removeAddingAttachments={true}
        />
      </FormGroup>
      <hr />
      <br />
      <FormGroup>
        <DateTimeRange
          inputTitle="Live Period"
          startDate={values.activate_on}
          endDate={values.deactivate_on}
          onStartDateChange={(value) => setFieldValue("activate_on", value)}
          onEndDateChange={(value) => setFieldValue("deactivate_on", value)}
          startDateTextLabel="From"
          endDateTextLabel="Until"
          startDateFor="activate_on"
          endDateFor="deactivate_on"
          startDateError={errors.activate_on}
          endDateError={errors.deactivate_on}
        />
      </FormGroup>
      <hr />
      <br />
      <FormGroup>
        <LabelRow labelText="Remark" labelFor="remark">
          <Input
            type="text"
            name="remark"
            id="remark"
            placeholder="Write some remark here ..."
            className="borderless"
            onChange={handleChange("remark")}
            value={values.remark}
          />
        </LabelRow>
      </FormGroup>
      <hr />
      <br />
      <FormGroup>
        <Row className="left-padding">
          <Col sm={5}>
            <h4>
              <Label for="image">Cover Image</Label>
            </h4>
            <ImageSelection
              name="image"
              onChange={(file) => setFieldValue("image", file)}
              removeImage={(bool) => setFieldValue("remove_image", bool)}
              imageUrl={values.image}
            />
          </Col>
          <Col sm={1} />
          <Col sm={5}>
            <UiAction
              labelText="Post Action"
              labelFor="ui_action_attributes"
              selectedUiAction={values.ui_action_attributes.action_type}
              hasCallToAction={false}
              onChoiceChange={handleChange("ui_action_attributes.action_type")}
              onCallToActionChange={handleChange(
                "ui_action_attributes.call_to_action_text"
              )}
              onCallChange={handleChange(
                "ui_action_attributes.payload.phone_no"
              )}
              onEmailChange={handleChange(
                "ui_action_attributes.payload.email_address"
              )}
              onReferralProgramChange={handleChange(
                "ui_action_attributes.payload.referral_program"
              )}
              onBrowserChange={handleChange(
                "ui_action_attributes.payload.url"
              )}
              callToActionText={values.ui_action_attributes.call_to_action_text}
              phoneNumber={values.ui_action_attributes.payload.phone_no}
              emailAddress={values.ui_action_attributes.payload.email_address}
              browserAddress={values.ui_action_attributes.payload.url}
              referralProgram={values.ui_action_attributes.payload.referral_program}
              errors={errors.ui_action_attributes}
              contentLabelText="Third Level Banner"
            />
          </Col>
        </Row>
      </FormGroup>
      <br />
      <hr />
      <Row className="d-flex justify-content-center">
        <Button type="submit" color="primary" size="lg" disabled={submitting}>
          Create
        </Button>
      </Row>
      <div style={{ height: 75 }} />
    </Form>
  );
};

export default InfoPostForm;
