// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("trix");
require("@rails/actiontext");
require("datatables.net");
require("./cocoon");

// For react-rails
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import "./trix-or-treat";
import "bootstrap/dist/js/bootstrap.bundle";
import "tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4.min";
import "@fortawesome/fontawesome-free/js/all";
import "@fortawesome/fontawesome-free/css/all.css";
import "whatwg-fetch";
import initialiseDataTables from "datatables.net-bs4";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// $(document).ready(() => {
// $(document).on("cocoon:after-insert", (e, insertedItem, originalEvent) => {
//   const datetimepickers = insertedItem.find(".datetimepicker");
//   if (datetimepickers.length > 0) {
//     datetimepickers.datetimepicker({
//       locale: "en-sg",
//       icons: {
//         time: "far fa-clock",
//         date: "far fa-calendar",
//         up: "fas fa-arrow-up",
//         down: "fas fa-arrow-down",
//       },
//     });
//   }
// });
// });

document.addEventListener("turbolinks:load", () => {
  initialiseDataTables(window, $);
  $('[data-toggle="tooltip"]').tooltip();
});
