import React, { useState, useRef } from "react";
import { FormGroup, Button, Row } from "reactstrap";

const ImageSelection = (
  props = { name: "", onChange: null, imageUrl: null }
) => {
  const { name, onChange, imageUrl, removeImage } = props;

  const [currentImageUrl, setCurrentImageUrl] = useState(imageUrl);
  const [imageFile, setImageFile] = useState(null);
  const fileInputRef = useRef(null);

  const onClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onImageChange = (event) => {
    const input = event.target;
    if (input.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setCurrentImageUrl(event.target.result);
      };
      reader.readAsDataURL(input.files[0]);
      setImageFile(input.files[0]);
      if (onChange) {
        onChange(input.files[0]);
      }
      removeImage(false);
    }
  };

  const onRemovePressed = () => {
    if(imageUrl === currentImageUrl) {
      removeImage(true)
    }
    setCurrentImageUrl(null);
    setImageFile(null);
    onChange(null)
  }

  return (
    <FormGroup>
      <div
        onClick={onClick}
        className="image-selection d-flex justify-content-center"
      >
        {currentImageUrl ? (
          <img src={currentImageUrl} width={150} style={{ height: "100%" }} />
        ) : (
          <div className="d-flex justify-content-center align-items-center flex-column">
            <i
              className="far fa-image bfc-text-primary"
              style={{ fontSize: 32 }}
            ></i>
            <div className="gap" />
            <h5>add a cover image</h5>
          </div>
        )}
      </div>
      <input
        type="file"
        name={name}
        id={name}
        accept="image/jpeg, image/png"
        onChange={onImageChange}
        style={{ display: "none" }}
        ref={fileInputRef}
      />
      {currentImageUrl ? (
        <>
          <div style={{ height: 16 }} />
          <Row className="d-flex justify-content-center">
            <Button type="button" color="warning" onClick={onRemovePressed}>
              Remove
            </Button>
          </Row>
        </>
      ) : null}
    </FormGroup>
  );
};

export default ImageSelection;
