import React, { useState, useRef, useEffect, useCallback } from "react";

const getEditorContentLength = (ref) => {
  return ref.current.editor.getDocument().getLength()
}

const TrixEditor = (props) => {
  const { name, onChange, initialValue, removeLinking, removeAddingAttachments } = props;
  const [showToolbar, setShowToolbar] = useState(false);
  const trixToolbarRef = useRef();
  const trixInputRef = useRef();

  const onTrixInit = useCallback(() => {
    if(trixInputRef.current) {
      if(initialValue && initialValue.length > 0) setShowToolbar(true)
    }

    if(trixToolbarRef.current) {
      if(removeLinking) {
        const linkButton = trixToolbarRef.current.querySelector(".trix-button--icon-link")
        if(linkButton) {
          linkButton.remove();
        }
      }
      if(removeAddingAttachments) {
        const attachmentButtonGroup = trixToolbarRef.current.querySelector(".trix-button-group--file-tools");
        if(attachmentButtonGroup) {
          attachmentButtonGroup.remove();
        }
      }
    }
  }, [trixInputRef.current, trixToolbarRef.current])

  const onTrixFocus = useCallback(() => {
    setShowToolbar(true);
  }, []);

  const onTrixBlur = useCallback(() => {
    if (trixInputRef.current) {
      if (getEditorContentLength(trixInputRef) <= 0) {
        setShowToolbar(false);
      }
    } else {
      setShowToolbar(false);
    }
  }, [trixInputRef.current]);

  const onTrixChange = useCallback(
    (event) => {
      if (onChange) onChange(event.target.value);
    },
    [onChange]
  );

  useEffect(() => {
    if (trixInputRef.current) {
      trixInputRef.current.addEventListener("trix-initialize", onTrixInit);
      trixInputRef.current.addEventListener("trix-focus", onTrixFocus);
      trixInputRef.current.addEventListener("trix-blur", onTrixBlur);
    }

    return () => {
      if (trixInputRef.current) {
        trixInputRef.current.removeEventListener("trix-initialize", onTrixInit);
        trixInputRef.current.removeEventListener("trix-focus", onTrixFocus);
        trixInputRef.current.removeEventListener("trix-blur", onTrixBlur);
      }
    };
  }, [trixInputRef.current]);

  useEffect(() => {
    if (trixInputRef.current) {
      trixInputRef.current.addEventListener("trix-change", onTrixChange);
    }

    return () => {
      if (trixInputRef.current) {
        trixInputRef.current.removeEventListener("trix-change", onTrixChange);
      }
    };
  }, [trixInputRef.current, onTrixChange]);

  return (
    <>
      <input
        id={name}
        value={initialValue ? initialValue : ""}
        type="hidden"
        name={name}
      />
      <trix-toolbar
        ref={trixToolbarRef}
        class={showToolbar ? "trix-toolbar_show" : "trix-toolbar_hide"}
        id="content-toolbar"
      />
      <trix-editor
        input={name}
        toolbar="content-toolbar"
        ref={trixInputRef}
        class="borderless trix-input trix-content"
        placeholder="Write your post details here ..."
        contenteditable=""
        role="textbox"
        data-direct-upload-url="/rails/active_storage/direct_uploads"
        data-blob-url-template="/rails/active_storage/blobs/:signed_id/:filename"
      ></trix-editor>
    </>
  );
};

export default TrixEditor;
