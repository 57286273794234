import React, { useState } from "react";
import {
  Row,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import Input from "../inputs/Input";

const displayCallToActionButton = (value, hasCallToAction) => {
  switch (value) {
    case "call":
    case "browser":
    case "email":
    case "content":
      return hasCallToAction && true;
    default:
      return false;
  }
};

const SubpageText = (props) => {
  const { selection } = props;
  if (selection === "content") {
    return (
      <p style={{ marginTop: 16 }}>
        Noted, you will fill in the details for content page later!
      </p>
    );
  }

  return null;
};

const CallInput = (props) => {
  const { selection, onChange, value, error } = props;
  if (selection === "call") {
    return (
      <InputGroup style={{ marginTop: 16 }}>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>Phone Number</InputGroupText>
        </InputGroupAddon>
        <Input
          type="tel"
          onChange={onChange}
          id="call"
          name="call"
          value={value}
          error={error}
        />
      </InputGroup>
    );
  }

  return null;
};

const BrowserInput = (props) => {
  const { selection, onChange, value, error } = props;
  if (selection === "browser") {
    return (
      <InputGroup style={{ marginTop: 16 }}>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>URL</InputGroupText>
        </InputGroupAddon>
        <Input
          type="url"
          onChange={onChange}
          id="browser"
          name="browser"
          value={value}
          error={error}
        />
      </InputGroup>
    );
  }

  return null;
};

const EmailInput = (props) => {
  const { selection, onChange, value, error } = props;
  if (selection === "email") {
    return (
      <InputGroup style={{ marginTop: 16 }}>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>Email Address</InputGroupText>
        </InputGroupAddon>
        <Input
          type="email"
          onChange={onChange}
          id="email"
          name="email"
          value={value}
          error={error}
        />
      </InputGroup>
    );
  }

  return null;
};

const ReferralProgramInput = (props) => {
  const { selection, onChange, value, error } = props;
  if (selection === "referral") {
    return (
      <InputGroup style={{ marginTop: 16 }}>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>Referral Program</InputGroupText>
        </InputGroupAddon>
        <Input
          type="text"
          onChange={onChange}
          id="referral_program"
          name="referral_program"
          value={value}
          error={error}
        />
      </InputGroup>
    );
  }

  return null;
};

const UiAction = (props) => {
  const {
    labelText,
    labelFor,
    selectedUiAction,
    callToActionText,
    phoneNumber,
    emailAddress,
    browserAddress,
    referralProgram,
    hasNoContent,
    hasCallToAction,
    onChoiceChange,
    onCallToActionChange,
    onCallChange,
    onEmailChange,
    onBrowserChange,
    onRefferralProgramChange,
    contentLabelText,
    errors,
  } = props;
  const [selection, setSelection] = useState(selectedUiAction);

  const onSelectionChange = (event) => {
    const { value } = event.target;
    setSelection(value);
    if (onChoiceChange) {
      onChoiceChange(value);
    }
  };

  const errorToUse = errors ? errors : { payload: {} };

  return (
    <Row>
      <Label for={labelFor}>
        <h4>{labelText}</h4>
      </Label>
      <Input
        type="select"
        name={labelFor}
        id={labelFor}
        onChange={onSelectionChange}
        value={selection}
      >
        <option value="none">None</option>
        <option value="call">Call</option>
        <option value="browser">Browser</option>
        <option value="email">Email</option>
        {hasNoContent ? null : (
          <option value="content">
            {contentLabelText ? contentLabelText : "Sub Page"}
          </option>
        )}
      </Input>
      <CallInput
        selection={selection}
        onChange={onCallChange}
        value={phoneNumber}
        error={errorToUse.payload.phone_no}
      />
      <BrowserInput
        selection={selection}
        onChange={onBrowserChange}
        value={browserAddress}
        error={errorToUse.payload.url}
      />
      <EmailInput
        selection={selection}
        onChange={onEmailChange}
        value={emailAddress}
        error={errorToUse.payload.email_address}
      />
      <ReferralProgramInput
        selection={selection}
        onChange={onRefferralProgramChange}
        value={referralProgram}
        error={errorToUse.payload.referral_program}
      />
      {displayCallToActionButton(selection, hasCallToAction) ? (
        <InputGroup style={{ marginTop: 16 }}>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Button Text</InputGroupText>
          </InputGroupAddon>
          <Input
            id="callToAction"
            name="callToAction"
            type="text"
            onChange={onCallToActionChange}
            value={callToActionText}
          />
        </InputGroup>
      ) : null}
      <SubpageText selection={selection} />
    </Row>
  );
};

export default UiAction;
