import React, { useState, useEffect } from "react";
import { FormText, Row, Container } from "reactstrap";

const isAllCheckboxesChecked = (checkboxes, result) => {
  return (
    Object.values(result).filter((bool) => bool).length === checkboxes.length
  );
};

const generateDefaultSelectedState = (defaultCheckbox, checkboxes) => {
  const state = {};
  state[defaultCheckbox.value] = true;
  checkboxes.forEach((checkbox) => {
    state[checkbox.value] = false;
  });
  return state;
};

const generateInternalState = (
  defaultCheckbox,
  checkboxes,
  initialSelected
) => {
  let state = {};
  state[defaultCheckbox.value] = false;
  checkboxes.forEach((checkbox) => {
    state[checkbox.value] = false;
  });
  initialSelected.forEach((selected) => {
    if (state[selected] !== undefined) {
      state[selected] = true;
    }
  });
  if (isAllCheckboxesChecked(checkboxes, state)) {
    state = generateDefaultSelectedState(defaultCheckbox, checkboxes);
  }
  return state;
};

const filterForTrue = (selected) => {
  return Object.entries(selected).reduce((arr, [key, value]) => {
    if (value) {
      arr.push(key);
    }
    return arr;
  }, []);
};

// defaultCheckbox: {
//   name: string,
//   value: string
// },
// checkboxes: [
//   {
//     name: string
//     value: string
//   }, ...
// ],
// onChange: (value) => void,
// initialSelected: []string;
const CantikCheckboxGroup = (props) => {
  const {
    defaultCheckbox,
    checkboxes,
    onChange,
    initialSelected,
    error,
  } = props;
  const [selected, setSelected] = useState(
    generateInternalState(defaultCheckbox, checkboxes, initialSelected)
  );

  const onDefaultPressed = () => {
    const newSelected = generateDefaultSelectedState(
      defaultCheckbox,
      checkboxes
    );
    setSelected(newSelected);
    onChange(filterForTrue(newSelected));
  };

  const onCheckboxPressed = (value) => () => {
    if (onChange) {
      let newSelected = {
        ...selected,
        [defaultCheckbox.value]: false,
        [value]: !selected[value],
      };
      if (isAllCheckboxesChecked(checkboxes, newSelected)) {
        newSelected = generateDefaultSelectedState(defaultCheckbox, checkboxes);
      }
      setSelected(newSelected);
      onChange(filterForTrue(newSelected));
    }
  };

  return (
    <Container>
      <Row>
        <div className="cantik-checkbox-group">
          <button
            className={[
              "radio-button-default",
              selected[defaultCheckbox.value]
                ? "radio-button-default_selected"
                : "",
            ].join(" ")}
            type="button"
            value={defaultCheckbox.value}
            onClick={onDefaultPressed}
          >
            {defaultCheckbox.name}
          </button>
          {checkboxes.map((checkbox) => {
            const { name, value } = checkbox;
            return (
              <button
                className={[
                  "checkbox",
                  selected[value] ? "checkbox_selected" : "",
                ].join(" ")}
                type="button"
                value={value}
                onClick={onCheckboxPressed(value)}
                key={value}
              >
                {name}
              </button>
            );
          })}
        </div>
      </Row>

      {error ? (
        <Row>
          <FormText color="danger">{error}</FormText>
        </Row>
      ) : null}
    </Container>
  );
};

export default CantikCheckboxGroup;
