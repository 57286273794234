import React from "react";
import { Row, Col, Label } from "reactstrap";

const LabelRow = (props) => {
  const {
    labelText,
    labelFor,
    labelColStyle,
    labelColClassNames,
    labelStyle,
    children,
  } = props;
  return (
    <Row className="left-padding">
      <Col sm={1} style={labelColStyle} className={labelColClassNames}>
        <h4>
          <Label for={labelFor} style={labelStyle}>
            {labelText}
          </Label>
        </h4>
      </Col>
      <Col>{children}</Col>
    </Row>
  );
};

export default LabelRow;
