import React from 'react';
import {Input as ReactstrapInput, FormFeedback} from 'reactstrap'

const Input = (props) => {
  const {error, ...inputProps} = props;
  return (
    <>
      <ReactstrapInput invalid={!!error} {...inputProps} />
      {
        error ? <FormFeedback>{error}</FormFeedback> : null
      }
    </>
  )
}

export default Input;
