import AddHeadingButtons, {
  HeadingsTrixAttributes,
} from "./add_heading_buttons";
import AddTextColorButton, {
  TextColorTrixAttribute,
} from "./add_text_color_button";

let addHeadingButtons;
let addTextColorButton;
let headingsTrixAttributes = new HeadingsTrixAttributes();
let textColorTrixAttribute = new TextColorTrixAttribute();

headingsTrixAttributes.attach();
textColorTrixAttribute.attach();

const onLoad = () => {
  if (!addHeadingButtons) {
    addHeadingButtons = new AddHeadingButtons();
    addHeadingButtons.attach();
  }
  if (!addTextColorButton) {
    addTextColorButton = new AddTextColorButton();
    addTextColorButton.attach();
  }
};

const onDestroy = () => {
  if (addHeadingButtons) {
    addHeadingButtons.detach();
    addHeadingButtons = undefined;
  }
  if (addTextColorButton) {
    addTextColorButton.detach();
    addTextColorButton = undefined;
  }
};

document.addEventListener("turbolinks:load", onLoad);
document.addEventListener("turbolinks:before-cache", onDestroy);
document.addEventListener("turbolinks:before-render", onDestroy);
