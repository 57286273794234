import React, { useState, useEffect } from "react";
import { Row, Col, Form, Alert, ListGroup, ListGroupItem } from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const PostsCollectionForm = (props) => {
  const { postsCollections, url, method } = props;

  const [collections, setCollections] = useState(postsCollections);
  const [visibleAlert, setVisibleAlert] = useState(false);

  const handleVisible = () => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 2000);
  };

  useEffect(() => {
    return () => {
      handleVisible();
    };
  }, []);

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(collections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCollections(items);

    const token = document.querySelector("[name=csrf-token]").content;

    fetch(url, {
      method: method,
      body: JSON.stringify({ posts_collections: items }),
      credentials: "same-origin",
      headers: {
        "X-CSRF-TOKEN": token,
        "content-type": "application/json",
      },
    })
      .then((res) => {
        if (res.status) {
          handleVisible();
        }
      })
      .catch((err) => {});
  }

  return (
    <Form>
      {visibleAlert ? (
        <Alert color="success">
          Collections orderings updated successfully.
        </Alert>
      ) : null}
      <ListGroupItem color="primary">
        <Row>
          <Col sm={2}>
            <h6>Name</h6>
          </Col>
          <Col sm={2}>
            <h6>Title</h6>
          </Col>
          <Col sm={1}>
            <h6>Display Qty</h6>
          </Col>
          <Col sm={1}>
            <h6>Status</h6>
          </Col>
          <Col sm={3}>
            <h6>Activate On</h6>
          </Col>
          <Col sm={3}>
            <h6>Deactivate On</h6>
          </Col>
        </Row>
      </ListGroupItem>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="postsCollections">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <ListGroup>
                {collections.map(
                  (
                    {
                      id,
                      name,
                      title,
                      display_quantity,
                      activate_on,
                      deactivate_on,
                    },
                    index
                  ) => {
                    return (
                      <Draggable
                        key={id.toString()}
                        draggableId={id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <a className="list-group-item list-group-item-action">
                              <Row>
                                <Col sm={2}>
                                  <p>{name}</p>
                                </Col>
                                <Col sm={2}>
                                  <p>{title}</p>
                                </Col>
                                <Col sm={1}>
                                  <p>{display_quantity}</p>
                                </Col>
                                <Col sm={1}>
                                  <p>
                                    {new Date() > moment(activate_on)
                                      ? "Active"
                                      : "Pending"}
                                  </p>
                                </Col>
                                <Col sm={3}>
                                  <p>
                                    {moment(activate_on).format(
                                      "DD/MM/YYYY LT"
                                    )}
                                  </p>
                                </Col>
                                <Col sm={3}>
                                  <p>
                                    {deactivate_on != null
                                      ? moment(deactivate_on).format(
                                          "DD/MM/YYYY LT"
                                        )
                                      : ""}
                                  </p>
                                </Col>
                              </Row>
                            </a>
                          </div>
                        )}
                      </Draggable>
                    );
                  }
                )}
              </ListGroup>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Form>
  );
};

export default PostsCollectionForm;
